<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xs="12" lg="12">
        <b-card>
          <h4><i class="nav-icon icon-layers"></i> Manager Role Editor</h4>
          <hr>
          <v-client-table class="roles-width" :columns="columns" :data="wtsData" :options="options" :theme="theme" id="dataTable">
            <div slot="budget" slot-scope="props">
              <h6 class="d-xs-block d-lg-none">Budget Table</h6>
              <span v-if="props.row.budget === 1">
                <button class="btn btn-sm btn-success w-100"><i class='fa fa-check'></i></button>
              </span>
            </div>
            <div slot="startend" slot-scope="props">
              <h6 class="d-xs-block d-lg-none">Category Dates Table</h6>
              <span v-if="props.row.startend === 1">
                <button class="btn btn-sm btn-success w-100"><i class='fa fa-check'></i></button>
              </span>
            </div>
            <div slot="authority" slot-scope="props">
              <h6 class="d-xs-block d-lg-none">dollar-sign Authority</h6>

              <span v-if="props.row.authority === 1">
                <button class="btn btn-sm btn-success w-100"><i class='fa fa-check'></i></button>
              </span>
            </div>
            <div slot="wts" slot-scope="props">
              <h6 class="d-xs-block d-lg-none">WTS Suspension</h6>
              <span v-if="props.row.wts === 1">
                <button class="btn btn-sm btn-success w-100"><i class='fa fa-check'></i></button>
              </span>
            </div>
            <div slot="edit" slot-scope="props">
              <h6 class="d-xs-inline d-lg-none">Edit</h6>
              <b-button size="sm" class="d-inline" v-b-modal.editRole variant="primary" @click="sendInfo(props.row.user, props.row.budget, props.row.startend, props.row.authority, props.row.wts)"><i class="fa fa-edit"></i></b-button>
            </div>
          </v-client-table>
        </b-card>
      </b-col>
    </b-row>
    <b-modal @shown="clearErrors" id="editRole" ref="editRole" title="Edit Roles" title-tag="h2" centered size="lg" hide-footer>
      <form @submit.prevent="validateBeforeSubmit" class="pb-2">
        <b-input-group class="mb-2">
          <b-form-checkbox class="ml-2 mt-1" v-model="budget" value="1" unchecked-value="0"> <p class="ml-3 mb-0">Budget Table | <span v-if="budget == 1"><strong>Active</strong></span><span v-if="budget != 1"> <strong>Inactive</strong></span></p></b-form-checkbox>
        </b-input-group>
        <b-input-group class="mt-3 mb-2">
          <b-form-checkbox class="ml-2 mt-1" v-model="startend" value="1" unchecked-value="0"> <p class="ml-3 mb-0">Category Dates Table | <span v-if="startend == 1"><strong>Active</strong></span><span v-if="startend != 1"> <strong>Inactive</strong></span></p></b-form-checkbox>
        </b-input-group>
        <b-input-group class="mt-3 mb-2">
          <b-form-checkbox class="ml-2 mt-1" v-model="authority" value="1" unchecked-value="0"> <p class="ml-3 mb-0">dollar-sign Authorities | <span v-if="authority == 1"><strong>Active</strong></span><span v-if="authority != 1"> <strong>Inactive</strong></span></p></b-form-checkbox>
        </b-input-group>
        <b-input-group class="mt-3 mb-2">
          <b-form-checkbox class="ml-2 mt-1" v-model="wts" value="1" unchecked-value="0"> <p class="ml-3 mb-0">WTS Suspension |  <span v-if="wts == 1"><strong>Active</strong></span><span v-if="wts != 1"> <strong>Inactive</strong></span></p></b-form-checkbox>
        </b-input-group>

        <i v-show="error" class="fa fa-exclamation-triangle mt-3"></i><span v-show="error" class="help is-danger">Error. Not authorized to update table. Please contact admin.</span>
        <b-button variant="success" class="mt-4" type="submit" block>Save</b-button>
      </form>
    </b-modal>


  </div>
</template>

<script>
import Vue from 'vue'
import {ClientTable, Event} from 'vue-tables-2'
Vue.use(ClientTable)

export default {
  name: 'ManagerAuthority',
  components: {
    ClientTable,
    Event,
  },
  data () {
    return {
      columns: ['users', 'budget', 'startend', 'authority', 'wts', 'edit'],
      wtsData: [],
      options: {
        headings: {
          users: 'Users',
          budget: 'Budget Table',
          startend: 'Category Dates Table',
          authority: 'dollar-sign Authority',
          wts: 'WTS Suspension'
        },
        sortable: [],
        filterable: false,
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        pagination: {
          chunk: 10,
          edge: false,
          nav: 'scroll'
        }
      },
      theme: 'bootstrap4',
      template: 'default',
      useVuex: false,
      users: null,
      budget: null,
      startend: null,
      authority: null,
      wts: null,
      error: false,
    }
  },
  methods: {
    clearErrors() {
      this.error = false;
      this.assignerror = false;
      this.editassignerror = false;
    },
    sendInfo(user, budget, startend, authority, wts){
      this.user = user;
      this.budget = budget;
      this.startend = startend;
      this.authority = authority;
      this.wts = wts;
    },
    loadData() {
      this.$http.get('/manager/get/wtses')
      .then(response => {
        if (response.status === 200) {
          //this.wtsData = response.data.result;
          this.wtsData = [
            {
              'users': 'WTS',
              'budget': 0,
              'startend': 1,
              'authority': 0,
              'wts': 0,
            },
            {
              'users': 'Manager 1',
              'budget': 1,
              'startend': 0,
              'authority': 0,
              'wts': 1,
            },
            {
              'users': 'Manager 2',
              'budget': 0,
              'startend': 0,
              'authority': 0,
              'wts': 0,
            },
            {
              'users': 'Manager 3',
              'budget': 0,
              'startend': 0,
              'authority': 0,
              'wts': 0,
            },
            {
              'users': 'Manager 4',
              'budget': 0,
              'startend': 0,
              'authority': 0,
              'wts': 0,
            },
            {
              'users': 'Manager 5',
              'budget': 0,
              'startend': 0,
              'authority': 0,
              'wts': 0,
            },
            {
              'users': 'Accountant',
              'budget': 1,
              'startend': 0,
              'authority': 1,
              'wts': 0,
            },
          ]
        }
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
      this.$http.get('/manager/get/projects')
      .then(response => {
        if (response.status === 200) {
          this.projectData = response.data.result;
        }
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
    }
  },
  mounted: function () {
    this.loadData();
  },
}
</script>

<style lang="scss">
@media screen and (min-width: 1024px){
  .roles-width th,
  .roles-width td{
    width: 150px;
  }
  .roles-width th:last-child{
    width: 50px;
    max-width: 50px;
  }
  .roles-width td:last-child{
    width: 50px;
    max-width: 50px;
  }
}
@media screen and (max-width: 1024px){
  .roles-width th {
    display: none;
  }
  .roles-width td {
    display:block;
  }
}
</style>
